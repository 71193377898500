var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center"
  }, [_vm.me ? _c('v-popover', {
    staticClass: "p-0 w-full v-popover--block",
    attrs: {
      "offset": "0"
    }
  }, [_c('div', {
    staticClass: "justify-start cursor-pointer pl-3 pr-4 py-3 my-0 items-center",
    class: [_vm.userIsImpersonating ? _vm.impersonatingContentClass : _vm.contentClass]
  }, [_vm.userIsImpersonating ? _c('Icon', {
    staticClass: "w-4 h-4 ml-1",
    attrs: {
      "name": "eyeOutline"
    }
  }) : _c('span', {
    staticClass: "bg-green-500 h-2 w-2 m-2 rounded-full"
  }), _c('div', {
    staticClass: "flex-grow font-medium pl-1 pr-2 truncate"
  }, [_vm._v(" " + _vm._s(_vm.me.primaryContact.name && _vm.me.primaryContact.name.trim() != '' ? _vm.me.primaryContact.name : _vm.me.email) + " ")]), _c('div', [_c('Icon', {
    attrs: {
      "name": _vm.iconName
    }
  })], 1)], 1), _vm.enableResponsiveAvatar ? _c('div', {
    staticClass: "block md:hidden cursor-pointer"
  }, [_vm.userIsImpersonating ? _c('div', {
    staticClass: "w-10 h-10 text-center leading-10 rounded-full font-extrabold flex items-center justify-center bg-red-600 text-white"
  }, [_c('Icon', {
    attrs: {
      "name": "eyeOutline"
    }
  })], 1) : _vm._e(), _c('UserAvatar', {
    attrs: {
      "id": _vm.me.id,
      "height": "10"
    }
  })], 1) : _vm._e(), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    staticClass: "text-right"
  }, [!_vm.userIsImpersonating && this.$route.name == 'User' ? _c('li', [_c('Impersonate', {
    attrs: {
      "userId": this.$route.params.id
    }
  }, [_c('button', {
    staticClass: "btn bg-white text-yellow-600 hover:bg-yellow-100 flex items-center"
  }, [_c('Icon', {
    staticClass: "h-4 w-4 mr-2 -ml-2",
    attrs: {
      "name": "eyeOutline"
    }
  }), _vm._v(" Impersonate User (" + _vm._s(this.$route.params.id) + ") ")], 1)])], 1) : _vm._e(), !_vm.userIsImpersonating && this.$route.name == 'Campaign' && _vm.campaign ? _c('li', [_c('Impersonate', {
    attrs: {
      "userId": _vm.campaign.showFrontend == 'All In Affiliates' ? 27499 : 4352,
      "path": _vm.campaign.showFrontend == 'All In Affiliates' ? `/affiliate/campaign/${this.$route.params.id}` : `/publisher/campaign/${this.$route.params.id}`
    }
  }, [_c('button', {
    staticClass: "btn bg-white text-yellow-600 hover:bg-yellow-100 flex items-center"
  }, [_c('Icon', {
    staticClass: "h-4 w-4 mr-2 -ml-2",
    attrs: {
      "name": "eyeOutline"
    }
  }), _vm._v(" View Campaign as Publisher ")], 1)])], 1) : _vm._e(), _vm.userIsImpersonating && _vm.iconName === 'chevronDown' ? _c('li', [_c('div', {
    staticClass: "flex md:hidden items-center px-4 py-3 bg-red-600 text-white rounded-t-sm",
    staticStyle: {
      "max-width": "167px"
    }
  }, [_c('p', {
    staticClass: "truncate"
  }, [_vm._v(" " + _vm._s(_vm.me.primaryContact.name && _vm.me.primaryContact.name.trim() != '' ? _vm.me.primaryContact.name : _vm.me.email) + " ")])])]) : _vm._e(), _vm.userIsImpersonating ? _c('li', [_c('EndImpersonate', [_c('button', {
    staticClass: "btn bg-white text-yellow-600 hover:bg-yellow-100 flex items-center"
  }, [_c('Icon', {
    staticClass: "h-4 w-4 mr-2 -ml-2",
    attrs: {
      "name": "eyeOffOutline"
    }
  }), _vm._v(" End Impersonate ")], 1)])], 1) : _vm._e(), !_vm.userIsImpersonating ? _c('li', [_c('Logout', [_c('button', {
    staticClass: "btn bg-white text-blue-600 hover:bg-blue-100 flex items-center w-full"
  }, [_c('Icon', {
    staticClass: "h-4 w-4 mr-2 -ml-2",
    attrs: {
      "name": "logout"
    }
  }), _vm._v(" Log Out ")], 1)])], 1) : _vm._e()])])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }