var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SideBarRightItem', {
    scopedSlots: _vm._u([{
      key: "root",
      fn: function () {
        return [_c('div', {
          key: _vm.user.accountManager ? _vm.user.accountManager.id : 0,
          staticClass: "x-header group cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.openSyncAccountManagerWidget();
            }
          }
        }, [_c('div', {
          staticClass: "x-icon-container"
        }, [_vm.user.accountManager ? _c('UserAvatar', {
          attrs: {
            "id": _vm.user.accountManager.id,
            "height": "10"
          }
        }) : _c('div', {
          staticClass: "text-white w-10 h-10 text-center leading-10 rounded-full font-extrabold bg-gray-300"
        }, [_c('Icon', {
          staticClass: "p-2",
          attrs: {
            "name": "accountQuestion",
            "size": 10
          }
        })], 1), _vm._v(" --> ")], 1), _c('div', {
          staticClass: "x-header-text flex-1"
        }, [_c('div', {
          staticClass: "text-xs font-semibold text-gray-500"
        }, [_vm._v(" Account Manager ")]), _c('div', {
          staticClass: "text-gray-700"
        }, [_vm.user.accountManager && _vm.user.accountManager.primaryContact ? [_vm._v(" " + _vm._s(_vm.user.accountManager.primaryContact.name) + " ")] : [_c('span', {
          staticClass: "text-gray-400"
        }, [_vm._v(" Not Assigned")])]], 2)]), _c('Icon', {
          staticClass: "text-blue-600 mx-2 invisible group-hover:visible",
          attrs: {
            "name": "pencil"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('SideBarRightItem', {
    attrs: {
      "title": "Traffic Sources"
    }
  }, [_c('EditableValue', {
    ref: "trafficSourcesEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'trafficType';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'trafficType',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "orange",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Verticals"
    }
  }, [_c('EditableValue', {
    ref: "verticalsEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'vertical';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'vertical',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "blue",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['vertical']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Preferred Geos"
    }
  }, [_c('EditableValue', {
    ref: "preferredGeosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'countryCode';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'countryCode',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['countryCode']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Regional Geos"
    }
  }, [_c('EditableValue', {
    ref: "geosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'geoLocation';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'geoLocation',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['geoLocation']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Tracking Platform"
    }
  }, [_c('EditableValue', {
    ref: "trackingPlatformEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'trackingPlatform';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'trackingPlatform',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "purple",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trackingPlatform']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Referred By"
    }
  }, [_c('EditableValue', {
    ref: "referredEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'referredOption';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.user.id,
          type: 'referredOption',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "gray",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['referredOption']
                }
              },
              first: 500
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }])
  })], 1), _c('SideBarRightItem', {
    staticClass: "flex-1",
    attrs: {
      "title": "Comments",
      "icon": "message",
      "count": _vm.user.commentsAdmin.length
    }
  }, [_c('SideBarComments', {
    attrs: {
      "comments": _vm.user.commentsAdmin,
      "modelId": _vm.user.id,
      "modelClass": "User"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }