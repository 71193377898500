var render = function render(){
  var _vm$data$images;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slide flex px-4 py-3"
  }, [_c('div', {
    staticClass: "w-2/5 flex justify-center items-center"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Campaign',
        params: {
          id: _vm.data.id
        }
      }
    }
  }, [((_vm$data$images = _vm.data.images) === null || _vm$data$images === void 0 ? void 0 : _vm$data$images.length) > 0 ? _c('img', {
    staticClass: "object-cover h-48 w-56 rounded-md shadow block",
    attrs: {
      "src": _vm.data.images[0].conversions.filter(function (image) {
        return image.conversion === 'thumb';
      })[0].storageUrl
    }
  }) : _vm._e()])], 1), _c('div', {
    staticClass: "pl-5 my-1 w-3/5"
  }, [_c('span', {
    staticClass: "font-bold text-xl pb-2"
  }, [_c('router-link', {
    staticClass: "text-blue-600 hover:text-blue-700",
    attrs: {
      "to": {
        name: 'Publisher Campaign',
        params: {
          id: _vm.data.id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.data.label))])], 1), _c('div', {
    staticClass: "flex my-1 flex-wrap gap-y-1"
  }, [_vm._l(_vm.data.verticalTags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-2 inline-flex"
    }, [_c('Tag', {
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))])], 1);
  }), _vm._l(_vm.data.payoutDescriptions, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "mr-2 inline-flex"
    }, [_c('Tag', {
      staticClass: "mr-2",
      attrs: {
        "color": "inverse"
      }
    }, [_vm._v(_vm._s(tag))])], 1);
  })], 2), _c('div', {
    staticClass: "mt-4"
  }, [_vm._l(_vm.data.capDefaults, function (capDefault) {
    return _c('div', {
      key: capDefault.id,
      staticClass: "font-medium inline-block mr-8"
    }, [_vm._v(" " + _vm._s(_vm.formatCapToString(capDefault)) + " ")]);
  })], 2), _c('div', {
    staticClass: "flex mt-1"
  }, [_c('span', {
    staticClass: "font-medium pr-3"
  }, [_vm._v("Available Geos:")]), _c('expandable-countries-from-payouts', {
    attrs: {
      "payouts": _vm.data.payouts
    }
  })], 1), _c('div', {
    staticClass: "flex justify-start mt-4"
  }, [_c('router-link', {
    staticClass: "font-bold whitespace-normal",
    attrs: {
      "to": {
        name: 'Publisher Campaign',
        params: {
          id: _vm.data.id
        }
      }
    }
  }, [_c('div', {
    staticClass: "font-bold py-2 px-4 rounded-full uppercase",
    class: _vm.buttonStatus
  }, [_vm._v(" " + _vm._s(_vm.buttonStatusText) + " ")])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }