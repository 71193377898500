<template>
  <div>
    <QuarantineCampaignAlert v-if="me" :publisher="me" />
    <widget class="mb-2">
      <widget-header class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <PublisherCampaignFilters></PublisherCampaignFilters>
        </template>
      </widget-header>
    </widget>

    <div class="mb-2 flex justify-end mr-3" v-if="!gridViewHighlight">
      <div class="tooltip" v-if="listView">
        <div
          @click="gridViewTransform"
          class="bg-pink-100 p-2 rounded-full cursor-pointer hover:bg-pink-200 hover:scale-110 shadow-md
          transition transform duration-500 ease-in-out   "
        >
          <Icon name="grid" size="6" color="gray" class="cursor-pointer"></Icon>
        </div>
        <div class="highlight-tooltip">
          <p>
            Check out our new <span class="font-extrabold">Grid View!</span>
          </p>
          <i></i>
        </div>
      </div>
    </div>
    <div class="mb-2 flex justify-end mr-3" v-if="gridViewHighlight">
      <span v-if="!listView" @click="toggleView">
        <Icon name="list" size="6" color="gray" class="cursor-pointer"></Icon>
      </span>
      <span v-if="listView" @click="toggleView">
        <Icon name="grid" size="6" color="gray" class="cursor-pointer"></Icon>
      </span>
    </div>

    <CampaignsListView
      :campaigns="currentCampaigns || {}"
      :isLoading="$apollo.queries.publisherCampaignsLite.loading"
      @loadMore="loadMore"
      v-if="listView"
    ></CampaignsListView>

    <CampaignsGridView
      :campaigns="currentCampaigns || {}"
      :isLoading="$apollo.queries.publisherCampaignsLite.loading"
      @loadMore="loadMore"
      v-if="!listView"
    ></CampaignsGridView>
  </div>
</template>

<script>
import PublisherCampaignFilters from '@/views/Publisher/Campaigns/PublisherCampaignFilters'
import PUBLISHER_CAMPAIGNS_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignsQuery.gql'
import PUBLISHER_CAMPAIGNS_OPTIM_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignsOptimQuery.gql'

import { mapState, mapActions } from 'vuex'
import CampaignsListView from '@/views/Publisher/Campaigns/CampaignsListView.vue'
import CampaignsGridView from '@/views/Publisher/Campaigns/CampaignsGridView.vue'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import QuarantineCampaignAlert from '@/views/Publisher/Campaigns/QuarantineCampaignAlert.vue'

export default {
  components: {
    PublisherCampaignFilters,
    QuarantineCampaignAlert,
    CampaignsListView,
    CampaignsGridView
  },
  apollo: {
    publisherCampaignsLite: {
      query: PUBLISHER_CAMPAIGNS_OPTIM_QUERY,
      variables() {
        let devicesFilterValue = this.filters.devices.value
          ? this.filters.devices.value
          : []
        let allowedFilterValue = this.filters.allowed.value
          ? this.filters.allowed.value.map(value => value.name)
          : []
        let allowed = [...devicesFilterValue, ...allowedFilterValue]

        let countriesFilter =
          this.filters.countries.value &&
          this.filters.countries.value.length > 0
            ? {
                value: this.filters.countries.value
                  ? this.filters.countries.value.map(country => country.code)
                  : []
              }
            : undefined

        return {
          publisherId: this.$store.getters.authId,
          first: this.perPage,
          filters: {
            label: {
              value: [this.filters.campaign.value],
              modifiers: this.filters.campaign.modifiers
            },
            verticals: {
              value: this.filters.verticals.value
                ? this.filters.verticals.value.map(vertical => vertical.name)
                : null,
              modifiers: this.filters.verticals.modifiers
            },
            //countryCode: countriesFilter,
            authUserCountryCode: countriesFilter,
            allowed: {
              value: allowed && allowed.length > 0 ? allowed : null
            },
            campaignBrowser: {
              value: this.filters.campaignBrowser.value
                ? this.filters.campaignBrowser.value.map(item => item.name)
                : null
            },
            campaignOS: {
              value: this.filters.campaignOS.value
                ? this.filters.campaignOS.value.map(item => item.name)
                : null
            },
            //conversionType: {
            //  value: this.filters.conversionType.value
            //    ? this.filters.conversionType.value.map(item => item.name)
            //    : null
            //},
            payoutConversionType: {
              value: this.filters.payoutConversionType.value
                ? this.filters.payoutConversionType.value.map(item => item.id)
                : []
            },
            isSmartLink:
              this.filters.offerType.value &&
              this.filters.offerType.value == 'smart link'
                ? true
                : this.filters.offerType.value &&
                  this.filters.offerType.value == 'single offer'
                ? false
                : undefined,
            publisherStatus:
              this.filters.status.value && this.filters.status.value.length > 0
                ? { value: this.filters.status.value }
                : undefined
          },
          orderBy: [
            { column: 'featured_at', order: 'DESC' },
            { column: 'id', order: 'DESC' }
          ]
        }
      },
      result: function(result) {
        this.campaigns = result?.data?.publisherCampaignsLite
      },
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      deep: true
    },
    publisherCampaigns: {
      query: PUBLISHER_CAMPAIGNS_QUERY,
      variables() {
        let devicesFilterValue = this.filters.devices.value
          ? this.filters.devices.value
          : []
        let allowedFilterValue = this.filters.allowed.value
          ? this.filters.allowed.value.map(value => value.name)
          : []
        let allowed = [...devicesFilterValue, ...allowedFilterValue]

        let countriesFilter =
          this.filters.countries.value &&
          this.filters.countries.value.length > 0
            ? {
                value: this.filters.countries.value
                  ? this.filters.countries.value.map(country => country.code)
                  : []
              }
            : undefined

        return {
          publisherId: this.$store.getters.authId,
          first: this.perPage,
          filters: {
            label: {
              value: [this.filters.campaign.value],
              modifiers: this.filters.campaign.modifiers
            },
            verticals: {
              value: this.filters.verticals.value
                ? this.filters.verticals.value.map(vertical => vertical.name)
                : null,
              modifiers: this.filters.verticals.modifiers
            },
            //countryCode: countriesFilter,
            authUserCountryCode: countriesFilter,
            allowed: {
              value: allowed && allowed.length > 0 ? allowed : null
            },
            campaignBrowser: {
              value: this.filters.campaignBrowser.value
                ? this.filters.campaignBrowser.value.map(item => item.name)
                : null
            },
            campaignOS: {
              value: this.filters.campaignOS.value
                ? this.filters.campaignOS.value.map(item => item.name)
                : null
            },
            //conversionType: {
            //  value: this.filters.conversionType.value
            //    ? this.filters.conversionType.value.map(item => item.name)
            //    : null
            //},
            payoutConversionType: {
              value: this.filters.payoutConversionType.value
                ? this.filters.payoutConversionType.value.map(item => item.id)
                : []
            },
            isSmartLink:
              this.filters.offerType.value &&
              this.filters.offerType.value == 'smart link'
                ? true
                : this.filters.offerType.value &&
                  this.filters.offerType.value == 'single offer'
                ? false
                : undefined,
            publisherStatus:
              this.filters.status.value && this.filters.status.value.length > 0
                ? { value: this.filters.status.value }
                : undefined
          },
          orderBy: [
            { column: 'featured_at', order: 'DESC' },
            { column: 'id', order: 'DESC' }
          ]
        }
      },
      result: function(result) {
        this.fullCampaigns = result?.data?.publisherCampaigns
      },
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      deep: true
    },
    me: {
      query: ME_QUERY,
      result(data) {
        this.gridViewHighlight = data?.data?.me.userDetails.gridViewed
      },
      update: data => data.me
    }
  },
  data() {
    return {
      publisherCampaigns: {},
      campaigns: {},
      fullCampaigns: {},
      page: 1,
      perPage: 20,
      loadMoreIncrement: 20,
      perPageOptions: [10, 25, 50, 100, 500],
      listView: true,
      gridViewHighlight: null,
      me: null
    }
  },
  computed: {
    currentCampaigns: function() {
      return this.$apollo.queries.publisherCampaigns.loading ? this.campaigns : this.fullCampaigns
    },
    ...mapState({
      filters: state => state.publisherCampaign.filters
    })
  },

  mounted() {
    //this.$events.listen('scrolledToBottom', this.loadMore)
  },
  updated() {},
  methods: {
    ...mapActions(['updateGlobalLoader']),
    loadMore() {
      this.perPage = this.perPage + this.loadMoreIncrement
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    toggleView() {
      return (this.listView = !this.listView)
    },
    gridViewTransform() {
      this.toggleView()
      this.$apollo.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: {
          input: {
            id: this.me.id,
            userDetails: {
              update: {
                id: this.me.userDetails.id,
                gridViewed: true
              }
            }
          }
        }
      })
    }
  }
}
</script>
<style scoped>
.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.tooltip .highlight-tooltip {
  @apply bg-pink-200 shadow-md text-black font-semibold text-xs rounded-lg mr-4 py-2 px-4;
  min-width: 205px;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
}

.tooltip .highlight-tooltip i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip .highlight-tooltip i::after {
  @apply bg-pink-200 shadow-md;
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>
