<template>
  <div
    class="border flex flex-col justify-start rounded-md shadow"
    style="height: 27rem;"
  >
    <router-link
      :to="{
        name: 'Publisher Campaign',
        params: { id: campaign.id }
      }"
    >
      <img
        v-if="campaign.images.length > 0"
        :src="
          campaign.images[0].conversions.filter(
            image => image.conversion === 'thumb'
          )[0].storageUrl
        "
        class="rounded-t-md object-cover h-48 w-full hover:opacity-75 transition ease-in duration-300"
      />
    </router-link>

    <div class="px-4 mt-5 flex flex-col flex-grow">
      <div class="overflow-hidden" style="height:3.005rem;">
        <Icon
          v-if="campaign.isFeatured"
          v-tooltip="'Featured'"
          class="mr-2  mt-1 text-orange-400 flex-shrink inline-flex leading-relaxed"
          name="star"
          style="    margin-top: -6px;"
        ></Icon>
        <span class="font-bold text-lg inline leading-5">{{
          campaign.name
        }}</span>

        <span class="text-gray-700 text-xs inline "> - {{ campaign.id }}</span>
      </div>

      <!-- tags start -->
      <div class="w-100 flex justify-start gap-x-3 gap-y-1 mt-1 flex-wrap">
        <div class="flex justify-center">
          <template v-if="campaign.verticalTags">
            <tag
              v-for="vertical in campaign.verticalTags"
              :key="vertical.name"
              class="mr-1"
              :color="vertical.color"
              >{{ vertical.name }}</tag
            >
          </template>
        </div>
      </div>
      <!-- tags end -->

      <div class="flex flex-col mt-1">
        <div class="mt-3 text-sm text-gray-700 ">
          <Icon name="mapMarkerMultiple" size="6" class="mapMarker "></Icon>
          <expandable-countries-from-payouts
            v-if="campaign.payouts"
            class="inline"
            :payouts="campaign.payouts"
          ></expandable-countries-from-payouts>
        </div>
        <div class="mt-1 text-sm text-gray-700 flex">
          <div>
            <Icon name="finance" size="6" class="mapMarker"></Icon>
          </div>
          <div v-if="campaign.payoutDescriptions?.length">
            <ExpandablePayouts 
              v-if="campaign.payouts"
              :payouts="campaign.payoutDescriptions"
            />
          </div>
        </div>
      </div>
    </div>

    <router-link
      :to="{
        name: 'Publisher Campaign',
        params: { id: campaign.id }
      }"
    >
      <div
        class="h-10 w-full text-center flex items-center justify-center font-bold uppercase flex-grow  py-2 rounded-b-md "
        :class="statusButton(campaign.status)"
      >
        {{ campaign.status }}
      </div>
    </router-link>
  </div>
</template>

<script>
import ExpandableCountriesFromPayouts from '@/components/general/ExpandableCountriesFromPayouts'
import PublisherStatusButton from '@/mixins/PublisherStatusButtonsMixin'
import ExpandablePayouts from '@/views/Publisher/Campaigns/ExpandablePayouts.vue'

export default {
  components: { ExpandableCountriesFromPayouts, ExpandablePayouts },
  mixins: [PublisherStatusButton],
  props: {
    campaign: {
      type: Object
    }
  },
  mounted() {},
  methods: {
    conversionTypeText(value) {
      if (value.length == 0) {
        return ['SmartLink']
      } else {
        return value.map(type => type.name)
      }
    }
  }
}
</script>
<style scoped>
.mapMarker {
  @apply text-blue-700 inline mr-2;
}
.cardSubheading {
  @apply text-xs font-bold text-blue-900 uppercase;
}
</style>
