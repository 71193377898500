<template>
  <Tabs show-type="show">
    <!-- <Tab :name="this.tab1"   :active="true">
      <template v-slot:aside>
        <Tag color="purple" v-if="algorithm1Weight !== null">
          <EditableValue
            v-if="algorithm1Weight !== null"
            type="contentEditable"
            :mutation="EDIT_ALGORITHM_USAGE_WEIGHT"
            :variables="{ input: { id: 2, weight: '{value}' } }"
            :value="algorithm1Weight"
            :transformValueForQuery="value => parseInt(value)"
          />%
        </Tag></template
      >
      <AlgorithmTwo />
    </Tab>

    <Tab :name="this.tab10">
      <template v-slot:aside
        ><Tag color="purple" v-if="algorithm10Weight !== null"
          ><EditableValue
            v-if="algorithm10Weight !== null"
            type="contentEditable"
            :mutation="EDIT_ALGORITHM_USAGE_WEIGHT"
            :variables="{ input: { id: 10, weight: '{value}' } }"
            :value="algorithm10Weight"
            :transformValueForQuery="value => parseInt(value)"
          />
          %</Tag
        ></template
      >
      <AlgorithmTen />
    </Tab> -->

    <Tab :name="this.tab11" :active="true">
      <template v-slot:aside
        ><Tag color="purple" v-if="algorithm11Weight !== null"
          ><EditableValue
            v-if="algorithm11Weight !== null"
            type="contentEditable"
            :mutation="EDIT_ALGORITHM_USAGE_WEIGHT"
            :variables="{ input: { id: 11, weight: '{value}' } }"
            :value="algorithm11Weight"
            :transformValueForQuery="value => parseInt(value)"
          />
          %</Tag
        ></template
      >
      <AlgorithmEleven />
    </Tab>
  
  </Tabs>
</template>

<script>
// import AlgorithmTwo from '@/views/Admin/Algorithms/AlgorithmTwo/Algorithm'
// import AlgorithmTen from '@/views/Admin/Algorithms/AlgorithmTen/Algorithm'
import AlgorithmEleven from '@/views/Admin/Algorithms/AlgorithmEleven/Algorithm'
import gql from 'graphql-tag'
import EDIT_ALGORITHM_USAGE_WEIGHT from '@/graphql/Algorithm/EditAlgorithmUsageWeight.gql'

export default {
  components: {
    // AlgorithmTwo,
    // AlgorithmTen,
    AlgorithmEleven,
  },
  apollo: {
    algorithms: {
      query: gql`
        {
          algorithms {
            id
            metaData
          }
        }
      `
    }
  },
  data() {
    return {
      EDIT_ALGORITHM_USAGE_WEIGHT,
      tableData: [],
      tab1Weight: null,
      tab2Weight: null,
      // tab1: '2 - yPanel (2D/4D/8D)',
      // tab10: '10 - Hourly (30 days + region)',
      tab11: '11 - Hourly (30 days, no reigon)'
    }
  },
  props: {},
  computed: {
    // algorithm1Weight() {
    //   if (
    //     !this.algorithms ||
    //     !this.algorithms[1] ||
    //     !this.algorithms[1].metaData
    //   ) {
    //     return null
    //   }
    //   return JSON.parse(this.algorithms[1].metaData).weight
    // },
    // algorithm10Weight() {
    //   if (
    //     !this.algorithms ||
    //     !this.algorithms[9] ||
    //     !this.algorithms[9].metaData
    //   ) {
    //     return null
    //   }
    //   return JSON.parse(this.algorithms[9].metaData).weight
    // },
    algorithm11Weight() {
      if (
        !this.algorithms ||
        !this.algorithms[10] ||
        !this.algorithms[10].metaData
      ) {
        return null
      }
      return JSON.parse(this.algorithms[10].metaData).weight
    },
  }
}
</script>

<style></style>
