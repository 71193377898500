<template
  ><div>
    <portal to="sidebar-right">
      <SideBarRightItem title="Convert to Active User">
        <YButton
          color="pink"
          @click="openConvertToActiveModal(user)"
          class="mx-4"
          >Convert to Active</YButton
        >
      </SideBarRightItem>
      <SideBarRightItem>
        <template v-slot:root>
          <div
            class="x-header group cursor-pointer"
            :key="user.accountManager ? user.accountManager.id : 0"
            @click="openSyncAccountManagerWidget()"
          >
            <div class="x-icon-container">
              <UserAvatar
                v-if="user.accountManager"
                :id="user.accountManager.id"
                height="10"
              ></UserAvatar>
              <div
                v-else
                class="
                  text-white
                  w-10
                  h-10
                  text-center
                  leading-10
                  rounded-full
                  font-extrabold
                  bg-gray-300
                "
              >
                <Icon name="accountQuestion" :size="10" class="p-2" />
              </div>
              -->
            </div>
            <div class="x-header-text flex-1">
              <div class="text-xs font-semibold text-gray-500">
                Account Manager
              </div>
              <div class="text-gray-700">
                <template
                  v-if="
                    user.accountManager && user.accountManager.primaryContact
                  "
                >
                  {{ user.accountManager.primaryContact.name }}
                </template>
                <template v-else>
                  <span class="text-gray-400"> Not Assigned</span>
                </template>
              </div>
            </div>
            <Icon
              name="pencil"
              class="text-blue-600 mx-2 invisible group-hover:visible"
            />
          </div>
        </template>
      </SideBarRightItem>

      <SideBarRightItem title="Traffic Sources">
        <EditableValue
          ref="trafficSourcesEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'trafficType')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'trafficType',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="orange"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trafficType'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Tracking Platform">
        <EditableValue
          ref="trackingPlatformEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'trackingPlatform')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'trackingPlatform',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="purple"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trackingPlatform'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Verticals">
        <EditableValue
          ref="verticalsEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'vertical')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'vertical',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="blue"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['vertical'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Preferred Geos">
        <EditableValue
          ref="preferredGeosEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'countryCode')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'countryCode',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="green"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['countryCode'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>
      <SideBarRightItem title="Regional Geos">
        <EditableValue
          ref="geosEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'geoLocation')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'geoLocation',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="green"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['geoLocation'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Referred By">
        <EditableValue
          ref="referredEditable"
          class="px-3"
          :value="user.tags.filter(tag => tag.type === 'referredOption')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: user.id,
              type: 'referredOption',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="gray"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['referredOption'] }
                },
                first: 500
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>
    </portal></div
></template>

<script>
import SyncAccountManagerWidget from '@/views/Admin/User/SyncAccountManagerWidget'
import LeadToActive from '@/views/Admin/Leads/LeadToActive.vue'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SYNC_TAGS_WITH_TYPE_MUTATION,
      TAGS_QUERY
    }
  },
  methods: {
    openSyncAccountManagerWidget(autoSubmit = false) {
      this.$modal.show(
        SyncAccountManagerWidget,
        {
          defaultManager: this.user.accountManager
            ? this.user.accountManager
            : null,
          userId: parseInt(this.user.id),
          autoSubmit: autoSubmit
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    openConvertToActiveModal(data) {
      this.$modal.show(
        LeadToActive,
        { lead: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
