<template>
  <div>
    <div v-if="campaignsPreview && campaignsPreview?.data">
      <div class="m-auto" style="max-width: 60rem;">
        <div class="block sm:flex text-center justify-between mb-5">
          <div>
            <span class="fw-500 mr-2">For:</span>
            <span v-if="campaignsPreview.data" class="font-semibold">{{
              campaignsPreviewData.mail.toEmail
            }}</span>
          </div>
          <div class="font-semibold">
            <i class="text-blue-500 fw-500"
              >* Details as of {{ prettyCreatedAt }}</i
            >
          </div>
        </div>
        <campaign-previews
          :value="campaignsPreviewData.campaigns"
          :publisherOptions="userDetails?.data[0] || {}"
        ></campaign-previews>
        <portal to="content-header">
          <div
            class="text-center w-full py-4 bg-blue-100 border-b border-blue-200 mb-4 text-gray-800"
          >
            <div>
              <img
                :src="ytzLogo"
                class="p-4 m-auto"
                style="width: 15rem; max-width: 100%;"
              />
            </div>
            <div class="-mt-4">
              <h3 class="mb-3 text-base mt-2 font-bold">Campaign Previews</h3>
            </div>
          </div>
        </portal>
        <portal to="content-header-right"> </portal>
      </div>
    </div>

    <div
      v-if="!campaignsPreview || !campaignsPreview.data || !id"
      class="mb-4 bg-red-200 border-t-4 border-red-800 rounded-b text-red-800 px-4 py-3 shadow-md font-bold"
    >
      <h2>
        Unfortunately the offer previews have expired. Please contact your AM
        for updated details.
      </h2>
    </div>
  </div>
</template>

<script>
import CampaignPreviews from '@/components/legacy/Campaigns/CampaignPreviews'
import gql from 'graphql-tag'
import YTZLogo from '@/assets/images/logo/logo_ytz.png'

export default {
  components: {
    CampaignPreviews
  },
  apollo: {
    campaignsPreview: {
      query: gql`
        query getCampaignsPreview($id: ID!) {
          campaignsPreview(id: $id) {
            id
            data
            createdAt
          }
        }
      `,
      variables() {
        return {
          id: this.id
        }
      },
      result(results) {
        if (results.data?.campaignsPreview) {
          this.userEmail = JSON.parse(
            results.data.campaignsPreview.data
          ).mail.toEmail
        }
      }
    },
    userDetails: {
      query: gql`
        query Users($first: Int!, $page: Int, $filters: UserFiltersInput) {
          users(first: $first, page: $page, filters: $filters) {
            data {
              userDetails {
                defaultRedirectParameters
              }
            }
          }
        }
      `,
      update: data => data.users,
      variables() {
        return {
          filters: {
            email: {
              value: [this.userEmail],
              modifiers: {
                matchType: 'contains'
              }
            },
            contacts: {},
            accountManagers: {},
            roles: {},
            referrer: {}
          },
          first: 20,
          page: 1
        }
      },
      skip() {
        return !this.userEmail
      }
    }
  },
  props: {
    campaigns: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      campaignsPreview: {},
      ytzLogo: YTZLogo,
      userEmail: null
    }
  },
  computed: {
    prettyCreatedAt() {
      return this.$luxon(this.campaignsPreview.createdAt, 'MMMM d yyyy')
    },
    campaignsPreviewData() {
      return JSON.parse(this.campaignsPreview.data)
    }
  },
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped></style>
