<template>
  <div v-if="user" style="height:100vh;">
    <portal to="top-bar-left-header">
      <div class="flex items-center">
        <div
          class="mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0"
          :class="
            `bg-${TextColorMap[user.status]}-500 hover:bg-${
              TextColorMap[user.status]
            }-600`
          "
        ></div>
        <div class="x-topbar-username break-all">{{ user.label }}</div>
      </div>
    </portal>

    <div class="flex items-center justify-between w-full h-full">
      <div class="flex flex-col lg:flex-row w-full items-start gap-4 h-full">
        <div class="w-full lg:w-1/3 rounded-lg p-2 flex flex-col h-full">
          <div class="flex flex-col text-center items-center text-gray-900">
            <Icon
              v-if="user.primaryContact.company"
              name="accountMultiple"
              size="12"
              color="gray"
            />
            <Icon v-else name="account" size="12" color="gray" />

            <span v-if="user.primaryContact.company">
              <span class="block font-semibold text-gray-800 text-lg">
                {{ user.primaryContact.company }}</span
              >
              <span class="block" v-if="user.primaryContact.company">{{
                user.name ?? ''
              }}</span>
            </span>

            <span v-if="!user.primaryContact.company">
              <span class="block font-semibold text-gray-800 text-lg">
                {{ user.name ?? '' }}
              </span>
            </span>

            <div class="flex flex-row items-center mt-3">
              <button
                @click="openLeadPriorityStatusModal(user)"
                class="hover:bg-gray-200 p-1 rounded"
              >
                <Icon
                  :name="priorityIconType(user.userDetails.priorityStatus)"
                  :size="6"
                  :class="priorityIconColor(user.userDetails.priorityStatus)"
                />
              </button>
              Priority Status:
              <span class="capitalize ml-2">
                {{
                  user.userDetails.priorityStatus
                    ? user.userDetails.priorityStatus
                    : 'Regular'
                }}</span
              >
            </div>
            <div
              class="flex flex-row items-center"
              v-if="user.commentsLead.length > 0"
            >
              <Icon
                name="circle"
                size="2"
                class="text-green-500 mr-2 text-sm"
              />
              Last Contacted:
              {{ user.commentsLead[0].createdAt | luxon }}
            </div>
          </div>

          <div class="mt-5 bg-gray-100 rounded-lg shadow">
            <div
              class="grid grid-cols-2"
              style="grid-template-columns: auto 40px"
            >
              <div class="flex flex-col gap-y-3 text-gray-700 p-4">
                <div v-if="user.primaryContact.country">
                  Country
                  <span class="text-black block mt-1">{{
                    user.primaryContact.country.name ?? ''
                  }}</span>
                </div>

                <div v-for="(value, key) in userDetailsMeta" :key="key">
                  <div>
                    {{ formatKey(key) }}
                    <span class="text-black block mt-1">
                      <span
                        v-if="
                          Array.isArray(value) &&
                            value.length &&
                            typeof value[0] !== 'object'
                        "
                      >
                        <ul>
                          <li v-for="(item, index) in value" :key="index">
                            {{ item }}
                          </li>
                        </ul>
                      </span>

                      <span
                        v-else-if="
                          Array.isArray(value) &&
                            value.length &&
                            value.every(
                              item => typeof item === 'object' && item !== null
                            )
                        "
                      >
                        <ul>
                          <li
                            v-for="(item, index) in value"
                            :key="index"
                            class="mb-2"
                          >
                            <div
                              v-for="(subValue, subKey) in item"
                              :key="subKey"
                            >
                              <strong>{{ formatKey(subKey) }}:</strong>
                              {{ subValue }}
                            </div>
                          </li>
                        </ul>
                      </span>

                      <span
                        v-else-if="
                          typeof value === 'object' &&
                            !Array.isArray(value) &&
                            value !== null
                        "
                      >
                        <div v-for="(subValue, subKey) in value" :key="subKey">
                          <strong>{{ formatKey(subKey) }}:</strong>
                          {{ subValue }}
                        </div>
                      </span>

                      <span v-else>{{ value }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="pt-2 pr-2" v-if="user.status == 'lead'">
                <YButton
                  @click="openLeadDetailsModal(user)"
                  style="padding: 0.25rem 0.5rem 0.5rem 0.5rem"
                >
                  <Icon
                    name="accountEdit"
                    size="6"
                    class="text-inverse-400 hover:text-inverse-500"
                  />
                </YButton>
              </div>
            </div>

            <div
              class="p-2"
              v-if="user.status == 'lead' && user.secondaryContact.length < 1"
            >
              <YButton color="inverse" @click="openSecondaryContactModal"
                >Merge User</YButton
              >
            </div>

            <div class="bg-gray-200 m-1 rounded px-3 pt-2 pb-3 text-gray-900">
              <div class="mb-3 flex justify-between">
                <div class="widget-title">Secondary Contacts</div>
                <button @click="openCreateSeconaryContactModal">
                  <Icon
                    name="plus"
                    :size="6"
                    class="hover:bg-gray-300 rounded"
                  />
                </button>
              </div>

              <ul
                v-for="user in user.secondaryContact"
                :key="user.id"
                class="mb-3 py-1 flex flex-row justify-between hover:bg-gray-300 rounded showElementParent"
              >
                <div class="">
                  <li class="font-semibold text-sm">{{ user.name ?? '' }}</li>
                  <li class="truncate" v-tooltip="user.email">
                    {{ user.email }}
                  </li>

                  <li v-if="user.messenger">
                    <Icon
                      :name="handleMessenger"
                      class="w-4 h-4 text-gray-600 inline"
                    />
                    {{ JSON.parse(user.messenger).handle }}
                  </li>
                </div>

                <div
                  class="py-1 px-2 cursor-pointer showElement"
                  v-tooltip="`Delete Secondary Contact`"
                  @click="deleteContact(user.id)"
                >
                  <Icon
                    name="close"
                    :size="6"
                    color="red"
                    class="hover:text-red-800"
                  />
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-2/3 rounded-lg gap-4 flex flex-col">
          <div
            class="grid grid-cols-1 xl:grid-cols-2 gap-4 bg-white border p-4 rounded"
          >
            <div class="grid-1 flex flex-col gap-y-4">
              <div v-if="user.status == 'lead'" class="flex flex-col">
                <span class="block flex flex-row text-gray-800 text-xs"
                  >Email
                  <Icon
                    name="pencil"
                    :size="3"
                    class="ml-1"
                    style="margin-top: 2px"
                /></span>

                <span class="flex flex-row align-center text-black mt-1">
                  <EditableValue
                    type="contentEditable"
                    :value="user.email"
                    :mutation="UPDATE_USER_EMAIL_MUTATION"
                    :variables="{
                      input: { id: user.id, email: '{value}' }
                    }"
                    placeholder="0"
                    class="-mr-1 -ml-1"
                    value-class="px-1"
                    :edit-focus-icon-enabled="false"
                  />
                </span>
              </div>
              <div v-else class="flex flex-col">
                <span class="block flex flex-row text-gray-800 text-xs"
                  >Email</span
                >
                <span class="text-black">{{ accountEmail(user) }}</span>
              </div>
              <div v-if="user.primaryContact.messenger" class="flex flex-col">
                <p class="text-gray-800 text-xs">Messenger</p>
                <span class="text-black block mt-1">
                  <Icon
                    :name="handleMessenger"
                    class="w-4 h-4 text-gray-600 inline"
                  />
                  {{ JSON.parse(user.primaryContact.messenger).handle }}</span
                >
              </div>
              <div class="flex flex-col">
                <p class="text-gray-800 text-xs">Company Website</p>
                <p>
                  {{
                    user.primaryContact.homepage
                      ? user.primaryContact.homepage.join(', ')
                      : 'Not provided'
                  }}
                </p>
              </div>
            </div>

            <div class="grid-2 flex flex-col gap-y-4">
              <div class="flex flex-col">
                <p class="text-gray-800 text-xs ">Company Financials</p>
                <p class="font-semibold text-base">
                  {{ user.billingContact?.name ?? '' }}
                </p>
                <p>{{ user.billingContact?.company ?? '' }}</p>
                <p>{{ user.billingContact?.email ?? '' }}</p>
                <p>
                  {{ user.billingContact?.address ?? '' }}
                  {{ user.billingContact?.city ?? '' }}
                </p>
                <p>
                  {{ user.billingContact?.region ?? '' }}
                  {{ user.billingContact?.country?.name ?? '' }}
                  {{ user.billingContact?.postalCode ?? '' }}
                </p>
                <!-- secondary contact -->
                <p class="font-semibold text-base">
                  {{ user.secondaryContact[0]?.name ?? '' }}
                </p>
                <p>{{ user.secondaryContact[0]?.company ?? '' }}</p>
                <p>{{ user.secondaryContact[0]?.email ?? '' }}</p>
                <p>
                  {{ user.secondaryContact[0]?.address ?? '' }}
                  {{ user.secondaryContact[0]?.city ?? '' }}
                </p>
                <p>
                  {{ user.secondaryContact[0]?.region ?? '' }}
                  {{ user.secondaryContact[0]?.country?.name ?? '' }}
                  {{ user.secondaryContact[0]?.postalCode ?? '' }}
                </p>
              </div>
            </div>
          </div>

          <LeadComments
            :modelId="user.id"
            :comments="user.commentsLead"
            modelClass="User"
            :user="user"
          ></LeadComments>
        </div>
      </div>
    </div>

    <div v-if="user.status == 'lead'">
      <LeadRightSideBar :user="user" />
    </div>
  </div>
</template>

<script>
import USER_QUERY from '@/graphql/User/UserQuery.gql'

import UPDATE_USER_EMAIL_MUTATION from '@/graphql/User/UpdateUserEmailMutation.gql'
import LeadComments from '@/views/Admin/Leads/LeadComments.vue'

import PriorityStyleMixin from '@/mixins/PriorityStyleMixin.js'
import PriorityStatusModal from '@/views/Admin/User/PriorityStatusModal.vue'
import LeadDetailsModal from '@/views/Admin/Leads/LeadDetailsModal.vue'
import TextColorMap from '@/assets/js/TextColorMap'

import MergeSecondaryContact from '@/views/Admin/Leads/MergeSecondaryContactModal.vue'
import CreateSecondaryContact from '@/views/Admin/Leads/CreateSecondaryContactModal.vue'
import gql from 'graphql-tag'
import LeadRightSideBar from '@/views/Admin/User/LeadRightSideBar.vue'

export default {
  mixins: [PriorityStyleMixin],
  components: {
    LeadComments,
    LeadRightSideBar
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      deep: false
    }
  },
  updated() {},
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      UPDATE_USER_EMAIL_MUTATION,
      TextColorMap,
      activeTab: this.tab,
      isSubmitting: false
    }
  },
  computed: {
    handleMessenger() {
      return this.user.messenger
        ? JSON.parse(this.user.messenger).app
          ? this.user.primaryContact.messenger
          : JSON.parse(this.user.primaryContact.messenger).app
        : ''
    },
    userDetailsMeta() {
      return JSON.parse(this.user.userDetails.meta)
    }
  },
  methods: {
    formatKey(key) {
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
    },
    deleteContact(id) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteContact($id: ID!) {
              deleteContact(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Secondary Contact deleted', 'Success')
          this.$apollo.queries.user.refetch()
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    },
    accountEmail(user) {
      return (
        user.email ||
        user.primaryContact.email ||
        user.billingContact.email ||
        'Email not provided'
      )
    },
    openSecondaryContactModal() {
      this.$modal.show(
        MergeSecondaryContact,
        { user: this.user },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateSeconaryContactModal() {
      this.$modal.show(
        CreateSecondaryContact,
        { user_id: this.user.id },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openLeadDetailsModal(data) {
      this.$modal.show(
        LeadDetailsModal,
        { lead: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },

    openLeadPriorityStatusModal(data) {
      this.$modal.show(
        PriorityStatusModal,
        { user: data },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  mounted() {
    this.refetch = false
    this.$events.listen('refreshCommunications', () => {
      this.$apollo.queries.user.refetch()
      this.refetch = true
    })
  }
}
</script>

<style scoped>
.showElement {
  display: none;
}

.showElementParent:hover .showElement {
  display: block;
}
</style>
