var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.yleaderEstimatedRevenues ? _c('YTable', {
    staticClass: "table-striped table-hover",
    attrs: {
      "data": _vm.yleaderEstimatedRevenues.data,
      "scroll-x": false,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_vm.showOfferId ? _c('td', [_vm._v(" " + _vm._s(props.rowData.offer.id) + " ")]) : _vm._e(), _c('td', [props.rowData.account ? _c('div', {
          staticClass: "flex items-center"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(props.rowData.account ? `${props.rowData.account.id}` : ''))]), _c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.account ? props.rowData.account.userName : undefined,
            expression: "\n              props.rowData.account\n                ? props.rowData.account.userName\n                : undefined\n            "
          }],
          attrs: {
            "color": props.rowData.account.yleadType == 'real' ? 'purple' : props.rowData.account.yleadType == 'lead' ? 'pink' : 'blue'
          }
        }, [_vm._v(_vm._s(props.rowData.account.yleadType))])], 1) : _c('div', [_vm._v(" " + _vm._s(props.rowData.accountId) + " ")])]), _c('td', [_vm._v(_vm._s(props.rowData.country ? props.rowData.country.code : ''))]), _c('td', [_c('div', [_vm._v(" " + _vm._s(props.rowData.campaign ? props.rowData.campaign.label : props.rowData.redirect && props.rowData.redirect.campaign ? props.rowData.redirect.campaign.label : '') + " ")])]), _c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('div', [_c('span', {
          staticClass: "font-semibold"
        }, [_vm._v(" " + _vm._s(props.rowData.redirect ? props.rowData.redirect.id : ''))]), _c('span', [_vm._v(" " + _vm._s(props.rowData.redirect ? '(' + props.rowData.redirect.subdomain + ')' : '') + " ")])]), _c('span', {
          staticClass: "text-xs italic text-gray-700"
        }, [_vm._v(_vm._s(props.rowData.redirect ? props.rowData.redirect.user.label : ''))])])]), _c('td', [_vm._v(_vm._s(props.rowData.sourceId))]), _c('td', [_vm._v("$" + _vm._s(props.rowData.value))]), _c('td', [_c('div', {
          staticClass: "text-right"
        }, [_c('v-popover', {
          staticClass: "inline-block",
          attrs: {
            "placement": "left",
            "offset": "4"
          }
        }, [_c('a', {
          staticClass: "tooltip-target inline-block text-gray-400 hover:text-gray-500"
        }, [_c('Icon', {
          attrs: {
            "name": "history"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "px-2 rounded overflow-auto",
          staticStyle: {
            "max-height": "20rem"
          }
        }, [props.rowData.activityPaginated && props.rowData.activityPaginated.length > 0 ? _vm._l(props.rowData.activityPaginated, function (activity) {
          return _c('div', {
            key: activity.id,
            staticClass: "x-activity-item text-xs py-2"
          }, [_c('div', {
            staticClass: "flex items-center justify-between"
          }, [_c('div', {
            staticClass: "font-bold mr-6"
          }, [_vm._v(" " + _vm._s(_vm._f("luxon")(activity.updatedAt, {
            output: {
              format: 'yyyy-MM-dd @ h:mm a'
            }
          })) + " ")]), _c('div', {
            staticClass: "font-semibold text-blue-600"
          }, [_vm._v(" " + _vm._s(activity.description.capitalize()) + " ")])]), _c('div', {
            staticClass: "flex justify-between"
          }, [_c('div', {
            staticClass: "text-gray-500 italic"
          }, [activity.causer ? [_vm._v(" " + _vm._s(activity.causer.email) + " ")] : _vm._e()], 2), _c('ul', {
            staticClass: "x-activity-list ml-6"
          }, _vm._l(activity.properties, function (property, key) {
            return _c('li', {
              key: key
            }, [_c('span', {
              staticClass: "text-gray-500"
            }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(property) + " ")]);
          }), 0)])]);
        }) : _c('div', {
          staticClass: "text-gray-400 py-2"
        }, [_vm._v("No History")])], 2)])], 2), _c('a', {
          staticClass: "text-gray-400 hover:text-red-500 cursor-pointer inline-block ml-1",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteRevenue(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trashCan"
          }
        })], 1)], 1)])];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.yleaderEstimatedRevenues.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.yleaderEstimatedRevenues.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1955010904)
  }, [_c('template', {
    slot: "header-row"
  }, [_vm.showOfferId ? _c('th', [_vm._v(" Offer ID ")]) : _vm._e(), _c('th', [_vm._v(" Account ")]), _c('th', [_vm._v(" Country ")]), _c('th', [_vm._v(" Campaign ")]), _c('th', [_vm._v(" Redirect ")]), _c('th', [_vm._v(" Source ")]), _c('th', [_vm._v(" Value ")]), _c('th', [_vm.selectedRowIds.length > 0 ? _c('YButton', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.bulkDeleteYleaders
    }
  }, [_vm._v("Bulk Delete")]) : _vm._e()], 1)]), _c('template', {
    slot: "secondHeaderRow"
  }, [_vm.showOfferId ? _c('td', [_vm._v("-")]) : _vm._e(), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Account",
      "track-by": "id",
      "label": "label",
      "options": _vm.accountOptions,
      "required": true
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex justify-between"
        }, [_c('span', [_vm._v(_vm._s(props.option.id) + " - " + _vm._s(props.option.userName))]), props.option.yleadType === 'real' || props.option.yleadType === 'lead' ? _c('span', {
          staticClass: "text-xxs"
        }, [_vm._v(_vm._s(props.option.yleadType.toUpperCase()) + " ")]) : _vm._e()])];
      }
    }], null, false, 2428108472),
    model: {
      value: _vm.accounts,
      callback: function ($$v) {
        _vm.accounts = $$v;
      },
      expression: "accounts"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Countries",
      "options": _vm.countryOptions,
      "track-by": "code",
      "label": "code"
    },
    model: {
      value: _vm.countries,
      callback: function ($$v) {
        _vm.countries = $$v;
      },
      expression: "countries"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Campaigns",
      "options": [],
      "track-by": "id",
      "label": "label",
      "disabled": _vm.redirects.length > 0 || _vm.sourceIds.length > 0,
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.campaigns,
      callback: function ($$v) {
        _vm.campaigns = $$v;
      },
      expression: "campaigns"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Redirects",
      "options": [],
      "track-by": "id",
      "label": "label",
      "disabled": _vm.campaigns.length > 0 || _vm.sourceIds.length > 0,
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.redirects,
      callback: function ($$v) {
        _vm.redirects = $$v;
      },
      expression: "redirects"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Sources",
      "options": [],
      "forceEdit": true,
      "disabled": _vm.campaigns.length > 0 || _vm.redirects.length > 0
    },
    model: {
      value: _vm.sourceIds,
      callback: function ($$v) {
        _vm.sourceIds = $$v;
      },
      expression: "sourceIds"
    }
  })], 1), _c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('td', {}, [_c('div', {
    staticClass: "flex items-center -ml-3 -mr-3"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.addRevenueRow(_vm.upsertRevenueObjects);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 1)])])], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }