<template>
  <div class="w-full">
    <div class="grid grid-cols-1 md:grid-cols-4  gap-1">
      <YInput v-model="filters.id.value" label="ID"></YInput>
      <YInput v-model="filters.email.value" label="Login"></YInput>
      <YInput v-model="filters.contactEmail.value" label="Email"></YInput>
      <Superselect
        title="Manager"
        v-model="filters.accountManager.value"
        :modifiers="filters.accountManager.modifiers"
        :multiple="false"
        track-by="id"
        label="label"
        :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: [],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
      <YInput
        v-model="filters.instantMessengerHandle.value"
        label="IM Handle"
      ></YInput>
      <superselect
        title="Country"
        v-model="filters.country.value"
        :modifiers="filters.country.modifiers"
        :multiple="true"
        label="label"
        track-by="code"
        :options="countries"
        :loading="$apollo.queries.countries.loading"
      ></superselect>
      <superselect
        title="Role"
        v-model="filters.role.value"
        :modifiers="filters.role.modifiers"
        :multiple="false"
        :options="filterOptions.role"
      ></superselect>
      <Superselect
        title="Referrer"
        v-model="filters.referrer.value"
        :modifiers="filters.referrer.modifiers"
        :options="publisherOptions || []"
        placeholder="Select"
        track-by="id"
        label="label"
        :multiple="true"
        :close-on-select="false"
        :query="PUBLISHER_OPTIONS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        edit-placeholder="Paste Publishers"
      ></Superselect>
      <superselect
        title="Status"
        v-model="filters.status.value"
        :modifiers="filters.status.modifiers"
        :multiple="false"
        :options="filterOptions.status"
      ></superselect>
      <!-- <superselect
        title="Order By"
        v-model="orderBy"
        :allow-empty="false"
        :multiple="false"
        :options="orderByOptions"
        track-by="label"
        label="label"
      ></superselect>-->
      <Superselect
        title="Traffic Sources"
        v-model="filters.trafficType.value"
        :modifiers="filters.trafficType.modifiers"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['trafficType'] }
          },
          first: 100
        }"
      ></Superselect>

      <Superselect
        title="Verticals"
        v-model="filters.verticals.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['vertical'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Referred Type"
        v-model="filters.referredOption.value"
        :modifiers="filters.referredOption.modifiers"
        :options="referedTypeFilter()"
      ></Superselect>
      <Superselect
        title="User Platform"
        v-model="filters.userPlatform.value"
        label="label"
        track-by="id"
        :multiple="false"
        :options="[
          { id: 'yTrack', label: 'yTrack Publisher' },
          { id: 'allIn', label: 'All In Affiliate' }
        ]"
      ></Superselect>
      <Superselect
        title="Priority Status"
        v-model="filters.priorityStatus.value"
        :multiple="false"
        :options="['regular', 'high', 'rejected']"
        class="capitalize"
      ></Superselect>

      <Superselect
        title="Tracking Platform"
        v-model="filters.trackingPlatform.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['trackingPlatform'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Complaints"
        v-model="filters.qualityMetrics.value"
        label="label"
        track-by="id"
        :options="[
          { id: 'qualityFlag', label: 'Has Quality Complaints' },
          { id: 'fraudFlag', label: 'Has Fraud Complaints' },
          { id: 'complianceFlag', label: 'Has Compliance Complaints' }
        ]"
      ></Superselect>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-5 gap-1 h-10 items-center">
      <div class="ml-1 flex items-center">
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            id="checkbox"
            class="w-4 h-4 rounded"
            v-model="filters.ipHistory.value"
          />
          <span class="ml-2">Duplicate IP Logged</span>
        </label>
      </div>
      <div class="ml-1 flex items-center">
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            id="checkbox"
            class="w-4 h-4 rounded"
            v-model="filters.autoRejected.value"
          />
          <span class="ml-2">Auto-Rejected Users</span>
        </label>
      </div>
      <div class="ml-1 flex items-center">
        <label class="inline-flex items-center">
          <input
            type="checkbox"
            id="checkbox"
            class="w-4 h-4 rounded"
            v-model="filters.autoApproved.value"
          />
          <span class="ml-2">Auto-Approved Users</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import ReferredTypeTagMixin from '@/mixins/ReferredTypeTagMixin.js'

export default {
  mixins: [ReferredTypeTagMixin],
  updated() {},

  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    }
  },
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      PUBLISHER_OPTIONS_QUERY,
      TAGS_QUERY,
      filterOptions: {
        status: ['Active', 'Pending', 'Rejected', 'Fraud', 'Inactive'],
        role: ['Admin', 'Publisher', 'Advertiser']
      },
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ],
      publisherOptions: [],
      countries: [],
      accountManagerOptions: []
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.user.filters
    })
  }
}
</script>

<style></style>
