export default {
  methods: {
    statusButton(value) {
      switch (value) {
        case 'pending approval':
          return '@apply bg-yellow-200 text-yellow-700 hover:bg-yellow-300'

        case 'declined':
          return '@apply bg-pink-100 text-pink-600 hover:bg-pink-200'

        case 'subscribed':
          return '@apply bg-gray-200 text-gray-700 hover:bg-gray-300'

        case 'unsubscribed':
          return '@apply bg-orange-100 text-orange-700 hover:bg-orange-200'

        case 'apply to run':
        case 'available':
          return '@apply bg-blue-600 text-white hover:bg-blue-700'

        default:
          return '@apply bg-gray-200 text-gray-700 hover:bg-gray-300'
      }
    }
  }
}
