var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.advertiser ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_vm._v(" " + _vm._s(_vm.advertiser.advertiserId) + " - " + _vm._s(_vm.advertiser.primaryContact.name) + " ")]), _vm._m(0), _c('div', {
    staticClass: "flex flex-row"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Details",
      "active": true
    }
  }, [_c('Widget', {
    staticClass: "rounded-t-none border-t-0"
  }, [_c('WidgetBody', [_c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }]
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.advertiser.primaryContact.name,
      callback: function ($$v) {
        _vm.$set(_vm.advertiser.primaryContact, "name", $$v);
      },
      expression: "advertiser.primaryContact.name"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Billing Name"
    },
    model: {
      value: _vm.advertiser.primaryContact.company,
      callback: function ($$v) {
        _vm.$set(_vm.advertiser.primaryContact, "company", $$v);
      },
      expression: "advertiser.primaryContact.company"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Home Page"
    },
    model: {
      value: _vm.advertiser.primaryContact.homepage,
      callback: function ($$v) {
        _vm.$set(_vm.advertiser.primaryContact, "homepage", $$v);
      },
      expression: "advertiser.primaryContact.homepage"
    }
  })], 1), _c('div', {
    staticClass: "mb-2"
  }, [_c('Superselect', {
    attrs: {
      "title": "Manager",
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "multiple": false,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.advertiser.accountManager,
      callback: function ($$v) {
        _vm.$set(_vm.advertiser, "accountManager", $$v);
      },
      expression: "advertiser.accountManager"
    }
  })], 1)])]), _c('widget-footer', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveAdvertiser,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveAdvertiser.apply(null, arguments);
      }
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Communications"
    }
  }, [_c('div', {
    staticClass: "pt-3"
  }, [_c('Lead', {
    attrs: {
      "id": _vm.advertiser.id
    }
  })], 1)]), _c('Tab', {
    attrs: {
      "name": "Accounts"
    }
  }, [_c('AdvertiserAccounts', {
    attrs: {
      "id": _vm.advertiser.id
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Contacts"
    }
  }, [_c('AdvertiserContacts', {
    attrs: {
      "id": _vm.advertiser.id
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Billing"
    }
  }, [_c('AdvertiserBilling', {
    attrs: {
      "user": _vm.advertiser
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Caps"
    }
  }, [_vm.advertiser ? _c('Caps', {
    attrs: {
      "cappableId": this.advertiser.id,
      "cappableType": "Advertiser"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": _vm.ruleTotalTabName
    }
  }, [_c('OfferPerspectiveRulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        advertisers: [_vm.advertiser]
      },
      "hide-all-offer-filters": true,
      "defaults-for-create": {
        filterType: {
          name: 'advertiser',
          label: 'Advertiser'
        },
        filterables: [_vm.advertiser]
      }
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    }
  })], 1)], 1)], 1)]), _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('SideBarRightItem', {
    attrs: {
      "title": "Traffic Sources"
    }
  }, [_c('EditableValue', {
    ref: "trafficSourcesEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.advertiser.tags.filter(function (tag) {
        return tag.type === 'trafficType';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.advertiser.id,
          type: 'trafficType',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "orange",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1178113921)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Verticals"
    }
  }, [_c('EditableValue', {
    ref: "verticalsEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.advertiser.tags.filter(function (tag) {
        return tag.type === 'vertical';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.advertiser.id,
          type: 'vertical',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "blue",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['vertical']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2547501416)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Preferred Geos"
    }
  }, [_c('EditableValue', {
    ref: "geosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.advertiser.tags.filter(function (tag) {
        return tag.type === 'countryCode';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.advertiser.id,
          type: 'countryCode',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['countryCode']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2835019770)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Regional Geos"
    }
  }, [_c('EditableValue', {
    ref: "geosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.advertiser.tags.filter(function (tag) {
        return tag.type === 'geoLocation';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.advertiser.id,
          type: 'geoLocation',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['geoLocation']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2354433743)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Tracking Platform"
    }
  }, [_c('EditableValue', {
    ref: "trackingPlatformEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.advertiser.tags.filter(function (tag) {
        return tag.type === 'trackingPlatform';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.advertiser.id,
          type: 'trackingPlatform',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "purple",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trackingPlatform']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 991470234)
  })], 1), _c('SideBarRightItem', {
    staticClass: "flex-1",
    attrs: {
      "title": "Comments",
      "icon": "message",
      "count": _vm.advertiser.comments ? _vm.advertiser.comments.length : null
    }
  }, [_c('SideBarComments', {
    attrs: {
      "comments": _vm.advertiser.commentsAdmin,
      "modelId": _vm.advertiser.id,
      "modelClass": "User",
      "cas": ""
    }
  })], 1)], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row align-items-center mb-2"
  }, [_c('div')]);

}]

export { render, staticRenderFns }