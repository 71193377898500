var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.campaigns ? _c('YTable', {
    attrs: {
      "data": _vm.campaigns.data || [],
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _vm$imageFileComputed, _vm$imageFileComputed2, _props$rowData, _props$rowData$payout;
        return [_c('td', [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "mr-5"
        }, [_c('EditableImage', {
          staticClass: "rounded mr-2x",
          attrs: {
            "imageUrl": ((_vm$imageFileComputed = _vm.imageFileComputed(props.rowData.images)) === null || _vm$imageFileComputed === void 0 ? void 0 : _vm$imageFileComputed.original) || null,
            "thumbnailUrl": ((_vm$imageFileComputed2 = _vm.imageFileComputed(props.rowData.images)) === null || _vm$imageFileComputed2 === void 0 ? void 0 : _vm$imageFileComputed2.imageThumbnailUrl) || null,
            "width": 32,
            "height": 24,
            "blankWidth": 20,
            "blankHeight": 20,
            "modelId": props.rowData.id,
            "editable": false,
            "modelClass": "campaign"
          }
        })], 1), _c('div', {
          staticClass: "flex"
        }, [props.rowData.isFeatured ? _c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Featured',
            expression: "'Featured'"
          }],
          staticClass: "mr-2 mt-1 text-orange-400 flex-shrink inline-flex leading-relaxed",
          attrs: {
            "name": "star"
          }
        }) : _vm._e(), _c('div', [_c('div', {
          staticClass: "mb-1 w-10/12 xl:w-full"
        }, [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Publisher Campaign',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "font-semibold text-gray-800 text-base inline leading-5 hover:text-gray-900 whitespace-normal"
        }, [_vm._v(" " + _vm._s(props.rowData.name) + " ")])]), _c('small', {
          staticClass: "text-gray-600"
        }, [_vm._v(_vm._s(props.rowData.id))])], 1)])], 1)])]), _c('td', [props.rowData.payouts ? _c('expandable-countries-from-payouts', {
          attrs: {
            "payouts": props.rowData.payouts
          }
        }) : _vm._e()], 1), _c('td', [(_props$rowData = props.rowData) !== null && _props$rowData !== void 0 && (_props$rowData$payout = _props$rowData.payoutDescriptions) !== null && _props$rowData$payout !== void 0 && _props$rowData$payout.length ? [_c('ExpandablePayouts', {
          attrs: {
            "payouts": props.rowData.payoutDescriptions
          }
        })] : _vm._e()], 2), _c('td', {
          staticStyle: {
            "text-align": "center"
          }
        }, [props.rowData.verticalTags ? _vm._l(props.rowData.verticalTags, function (vertical) {
          return _c('tag', {
            key: vertical.name,
            staticClass: "mr-1",
            attrs: {
              "color": vertical.color
            }
          }, [_vm._v(_vm._s(vertical.name))]);
        }) : _vm._e()], 2), _c('td', {
          staticStyle: {
            "text-align": "center"
          }
        }, [props.rowData.status ? _c('router-link', {
          attrs: {
            "to": {
              name: 'Publisher Campaign',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_c('YButton', {
          staticClass: "mr-1 font-bold uppercase text-xs",
          class: _vm.statusButton(props.rowData.status)
        }, [_vm._v(_vm._s(props.rowData.status))])], 1) : _vm._e()], 1)];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.campaigns && _vm.campaigns.paginatorInfo && _vm.campaigns.paginatorInfo.hasMorePages ? _c('div', {
          staticClass: "w-100 py-4"
        }, [_c('YButton', {
          staticClass: "block m-auto",
          attrs: {
            "color": "link",
            "is-loading": _vm.isLoading
          },
          on: {
            "click": _vm.onClickButton
          }
        }, [_vm._v("LOAD MORE")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 3001083240)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("Geos")]), _c('th', [_vm._v("Payout")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Vertical")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Status")])])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }