<template>
  <div v-if="advertiser">
    <portal to="top-bar-left-header">
      {{ advertiser.advertiserId }} -
      {{ advertiser.primaryContact.name }}
    </portal>
    <div class="flex flex-row align-items-center mb-2">
      <div></div>
    </div>
    <div class="flex flex-row"></div>
    <div class="row">
      <div class="col">
        <Tabs show-type="show" :route="tabRoute">
          <Tab name="Details" :active="true">
            <Widget class="rounded-t-none border-t-0">
              <WidgetBody>
                <div v-observe-visibility="visibilityChanged">
                  <div class="mb-2">
                    <YInput
                      class="mb-1"
                      label="Name"
                      v-model="advertiser.primaryContact.name"
                    />
                  </div>
                  <div class="mb-2">
                    <YInput
                      class="mb-1"
                      label="Billing Name"
                      v-model="advertiser.primaryContact.company"
                    />
                  </div>
                  <div class="mb-2">
                    <YInput
                      class="mb-1"
                      label="Home Page"
                      v-model="advertiser.primaryContact.homepage"
                    />
                  </div>
                  <div class="mb-2">
                    <Superselect
                      title="Manager"
                      v-model="advertiser.accountManager"
                      track-by="id"
                      label="label"
                      :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
                      :multiple="false"
                      :query-variables="{
                        filters: {
                          label: {
                            value: [],
                            modifiers: { matchType: 'contains' }
                          }
                        },
                        first: 25
                      }"
                    />
                  </div>
                </div>
              </WidgetBody>
              <widget-footer class="bg-gray-100 py-2 px-4 text-right">
                <y-button
                  class="btn btn-blue"
                  @click="saveAdvertiser"
                  @keydown.enter="saveAdvertiser"
                  :is-loading="isSubmitting"
                  >Save</y-button
                >
              </widget-footer>
            </Widget>
          </Tab>
          <Tab name="Communications">
            <div class="pt-3">
              <Lead :id="advertiser.id"></Lead>
            </div>
          </Tab>
          <Tab name="Accounts">
            <AdvertiserAccounts :id="advertiser.id"></AdvertiserAccounts>
          </Tab>
          <Tab name="Contacts">
            <AdvertiserContacts :id="advertiser.id"></AdvertiserContacts>
          </Tab>
          <Tab name="Billing">
            <AdvertiserBilling :user="advertiser"></AdvertiserBilling>
          </Tab>
          <Tab name="Caps">
            <Caps
              v-if="advertiser"
              :cappableId="this.advertiser.id"
              cappableType="Advertiser"
            />
          </Tab>
          <Tab :name="ruleTotalTabName">
            <OfferPerspectiveRulesTableWrapper
              :default-filter-values="{ advertisers: [advertiser] }"
              :hide-all-offer-filters="true"
              :defaults-for-create="{
                filterType: { name: 'advertiser', label: 'Advertiser' },
                filterables: [advertiser]
              }"
              @totalRulesTab="totalRulesTab"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
    <portal to="sidebar-right">
      <SideBarRightItem title="Traffic Sources">
        <EditableValue
          ref="trafficSourcesEditable"
          class="px-3"
          :value="advertiser.tags.filter(tag => tag.type === 'trafficType')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: advertiser.id,
              type: 'trafficType',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="orange"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trafficType'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Verticals">
        <EditableValue
          ref="verticalsEditable"
          class="px-3"
          :value="advertiser.tags.filter(tag => tag.type === 'vertical')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: advertiser.id,
              type: 'vertical',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="blue"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['vertical'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Preferred Geos">
        <EditableValue
          ref="geosEditable"
          class="px-3"
          :value="advertiser.tags.filter(tag => tag.type === 'countryCode')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: advertiser.id,
              type: 'countryCode',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="green"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['countryCode'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Regional Geos">
        <EditableValue
          ref="geosEditable"
          class="px-3"
          :value="advertiser.tags.filter(tag => tag.type === 'geoLocation')"
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: advertiser.id,
              type: 'geoLocation',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="green"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['geoLocation'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem title="Tracking Platform">
        <EditableValue
          ref="trackingPlatformEditable"
          class="px-3"
          :value="
            advertiser.tags.filter(tag => tag.type === 'trackingPlatform')
          "
          type="slot"
          :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
          sortBy="id"
          :transformValueForQuery="
            value => {
              return value.map(tag => tag.name)
            }
          "
          :variables="{
            input: {
              taggableId: advertiser.id,
              type: 'trackingPlatform',
              taggableType: 'user',
              tags: '{value}'
            }
          }"
        >
          <template v-slot:default="slotProps">
            <div v-if="slotProps.value && slotProps.value.length > 0">
              <Tag
                v-for="tag in slotProps.value"
                :key="tag.id"
                class="mr-1 my-1"
                color="purple"
                tabindex="0"
                @focus="
                  () => {
                    slotProps.focus()
                  }
                "
              >
                {{ tag.name }}
              </Tag>
            </div>

            <div
              v-else
              @click="
                () => {
                  slotProps.focus()
                }
              "
            >
              <span
                class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                >None</span
              >
            </div>
          </template>
          <template v-slot:focusSlot="slotProps">
            <Superselect
              title="Tags"
              :value="slotProps.value"
              @input="
                value => {
                  slotProps.updateInternalValue(value)
                }
              "
              :focusOnMount="true"
              :multiple="true"
              track-by="slug"
              label="name"
              placeholder="Select"
              class="box-width min-width-full"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['trackingPlatform'] }
                },
                first: 100
              }"
            ></Superselect>
          </template>
        </EditableValue>
      </SideBarRightItem>

      <SideBarRightItem
        title="Comments"
        icon="message"
        :count="advertiser.comments ? advertiser.comments.length : null"
        class="flex-1"
      >
        <SideBarComments
          :comments="advertiser.commentsAdmin"
          :modelId="advertiser.id"
          modelClass="User"
          cas
        ></SideBarComments>
      </SideBarRightItem>
    </portal>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdvertiserAccounts from '@/views/Admin/Advertiser/AdvertiserAccounts.vue'
import AdvertiserContacts from '@/views/Admin/Advertiser/AdvertiserContacts.vue'
import AdvertiserBilling from '@/views/Admin/Shared/BillingContact.vue'
import OfferPerspectiveRulesTableWrapper from '@/views/Admin/Rules/OfferPerspectiveRulesTableWrapper.vue'
import ADVERTISER_QUERY from '@/graphql/Advertiser/AdvertiserQuery.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'
import Caps from '@/views/Admin/Caps/Caps'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import Lead from '@/views/Admin/Leads/Lead.vue'

export default {
  mixins: [ValidationErrorsMixin],

  components: {
    AdvertiserAccounts,
    AdvertiserContacts,
    AdvertiserBilling,
    Caps,
    OfferPerspectiveRulesTableWrapper,
    Lead
  },
  apollo: {
    advertiser: {
      query: ADVERTISER_QUERY,
      variables() {
        return {
          advertiser_id: this.id
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'details'
    }
  },
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      TAGS_QUERY,
      SYNC_TAGS_WITH_TYPE_MUTATION,
      activeTab: this.tab,
      isVisible: true,
      advertiser: null,
      isSubmitting: false,
      ruleTotalTabName: null
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Advertiser', params: { id: this.id, tab: this.tab } }
    }
  },
  mounted() {
    this.$events.listen('refreshCommunications', () => {
      this.$apollo.queries.advertiser.refetch()
    })
  },
  updated() {},
  methods: {
    ...mapState({
      filters: state => state.advertiser.filters
    }),
    totalRulesTab(value) {
      return (this.ruleTotalTabName = 'Rules (' + value.total + ')')
    },
    saveAdvertiser() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.advertiser.id,
              contacts: {
                upsert: [
                  {
                    id: this.advertiser.primaryContact.id,
                    name: this.advertiser.primaryContact.name,
                    company: this.advertiser.primaryContact.company,
                    homepage: this.advertiser.primaryContact.homepage
                  }
                ]
              },
              accountManagers: this.advertiser.accountManager
                ? {
                    sync: [this.advertiser.accountManager.id]
                  }
                : undefined
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)

          console.log(error)
          this.isSubmitting = false
        })
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    }
  }
}
</script>
