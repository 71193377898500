var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0",
    class: `bg-${_vm.TextColorMap[_vm.user.status]}-500 hover:bg-${_vm.TextColorMap[_vm.user.status]}-600`
  }), _c('div', {
    staticClass: "x-topbar-username break-all"
  }, [_vm._v(" " + _vm._s(_vm.user.label) + " ")])])]), _c('portal', {
    attrs: {
      "to": "top-bar-left-header-after"
    }
  }, [_c('div', {
    staticClass: "flex items-center gap-1 ml-2"
  }, [_vm.user.tags.some(function (tag) {
    return tag.name == 'auto-rejected';
  }) ? _c('Tag', {
    attrs: {
      "color": "darkRed"
    }
  }, [_vm._v("auto-rejected")]) : _vm._e(), _c('Tag', {
    key: _vm.user.status,
    staticClass: "hidden md:inline-flex",
    on: {
      "click": function ($event) {
        return _vm.openSetStatusWidget();
      }
    }
  }, [_vm._v(_vm._s(_vm.user.status))]), _vm.user.roles.some(function (role) {
    return role.name == 'all-in-user';
  }) ? _c('Tag', {
    attrs: {
      "color": "darkBlue"
    }
  }, [_vm._v("All-In User")]) : _vm._e(), _vm.user.isQuarantined ? _c('Tag', {
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("Quarantined"), _c('span', {
    staticClass: "bg-white rounded-lg px-1 ml-1 -mr-1"
  }, [_vm._v(_vm._s(_vm.user.quarantine.level))])]) : _vm._e()], 1)]), _vm._m(0), _c('div', {
    staticClass: "flex flex-row"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Overview",
      "active": true
    }
  }, [_c('Overview', {
    attrs: {
      "filters": _vm.dailyGraphFilters,
      "user": _vm.user
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Stats"
    }
  }, [_c('simple-report', {
    staticClass: "col-span-4 mt-4",
    attrs: {
      "filters": _vm.reportFilters
    }
  }, [_c('h4', {
    staticClass: "widget-title",
    attrs: {
      "slot": "table-header"
    },
    slot: "table-header"
  }, [_vm._v("Daily Summary")])])], 1), _c('Tab', {
    attrs: {
      "name": "Details"
    }
  }, [_c('Widget', {
    staticClass: "rounded-t-none border-t-0"
  }, [_c('WidgetBody', [_c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    staticClass: "mt-2",
    attrs: {
      "name": "Primary",
      "active": true
    }
  }, [_c('PrimaryContact', {
    attrs: {
      "user": _vm.user
    }
  })], 1), _vm.user.billingContact ? _c('Tab', {
    attrs: {
      "name": "Billing"
    }
  }, [_c('BillingContact', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Details"
    }
  }, [_c('Details', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Pay Details"
    }
  }, [_vm.user.tipaltiDetails.publisherDetails ? _c('iframe', {
    staticClass: "w-full",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": _vm.user.tipaltiDetails.publisherDetails,
      "height": "800"
    }
  }) : _vm._e()]) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Wise Details"
    }
  }, [_c('WiseDetails', {
    attrs: {
      "user": _vm.user
    }
  })], 1) : _vm._e(), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Pay History"
    }
  }, [_vm.user.tipaltiDetails.paymentHistoryLink ? _c('iframe', {
    staticClass: "w-full",
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": _vm.user.tipaltiDetails.paymentHistoryLink,
      "height": "800"
    }
  }) : _vm._e()]) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Password"
    }
  }, [_c('div', {
    staticClass: "py-2"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.openUpdatePasswordModal
    }
  }, [_vm._v("Change Password")])], 1)])], 1)], 1)], 1)], 1), _c('Tab', {
    attrs: {
      "name": "Communications"
    }
  }, [_c('div', {
    staticClass: "pt-3"
  }, [_c('Lead', {
    attrs: {
      "id": _vm.user.id
    }
  })], 1)]), _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_c('Payouts', {
    attrs: {
      "payouts": _vm.user.payouts,
      "userId": parseInt(_vm.id),
      "level": "user"
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Postbacks"
    }
  }, [_c('Postbacks', {
    attrs: {
      "userId": parseInt(_vm.id)
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Redirects"
    }
  }, [_c('RedirectsTable', {
    attrs: {
      "override-filters": {
        user: {
          value: [{
            id: _vm.id
          }]
        }
      }
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "History"
    }
  }, [_c('UserHistory', {
    attrs: {
      "user": _vm.user,
      "userActivity": _vm.userActivity
    }
  })], 1), _vm.isPublisher ? _c('Tab', {
    attrs: {
      "name": "Rules"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_c('Tag', [_vm._v(_vm._s(_vm.rulesTotalCount))])];
      },
      proxy: true
    }], null, false, 1623784179)
  }, [_c('RulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        publishers: [_vm.user]
      },
      "hide-filters": ['redirects', 'campaigns', 'publishers', 'sources']
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    },
    scopedSlots: _vm._u([{
      key: "beforeTable",
      fn: function () {
        return [_c('div', {
          staticClass: "flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200"
        }, [_c('div', {
          staticClass: "text-muted"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected ")])]), _c('div', {
          staticClass: "flex gap-4"
        }, [_c('YButton', {
          attrs: {
            "color": "red",
            "disabled": !_vm.rowsAreSelected
          },
          on: {
            "click": _vm.openDeleteRulesModal
          }
        }, [_vm._v("Delete")]), _c('YButton', {
          attrs: {
            "color": "blue",
            "disabled": _vm.rowsAreSelected
          },
          on: {
            "click": _vm.openCreateRulesModal
          }
        }, [_vm._v("Create Rules")])], 1)])];
      },
      proxy: true
    }], null, false, 704410060)
  })], 1) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Quarantine"
    }
  }, [_vm.user.isQuarantined && _vm.user.caps.length > 0 ? _c('Quarantine', {
    staticClass: "my-4",
    attrs: {
      "quarantine": _vm.user.quarantine,
      "current-payout": _vm.user.caps.filter(function (cap) {
        return cap.type === 'payout' && cap.frequency == 'monthly';
      })[0].currentValue
    },
    on: {
      "deleted": _vm.refetch
    }
  }) : _c('div', {
    staticClass: "my-4 text-center"
  }, [_c('span', {
    staticClass: "italic text-gray-600"
  }, [_vm._v("User is not quarantined")])])], 1)], 1)], 1)]), _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('UserRightSideBar', {
    attrs: {
      "user": _vm.user
    }
  })], 1)], 1) : _c('div', [_c('Spinner', {
    staticClass: "text-green-500",
    attrs: {
      "size": 10
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row align-items-center mb-2"
  }, [_c('div')]);

}]

export { render, staticRenderFns }