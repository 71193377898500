<template>
  <div
    class="grid grid-cols-1 gap-3 m-auto p-3 bg-white border-l border-r border-b pt-5 pb-8 px-6 rounded-b-lg shadow-xl"
    style="max-width: 24rem;"
  >
    <h4 class="font-bold text-base uppercase text-inverse-800">
      Reset Password
    </h4>
    <p class="text-gray-700">
      Enter the email address associated with your account to reset your
      password.
    </p>
    <YInput label="Email" v-model="email" />
    <YButton
      class="w-full"
      color="pink"
      @click="submit"
      :is-loading="isSubmitting"
      :disabled="requestSent || isSubmitting"
      >Submit</YButton
    >
    <YAlert
      v-if="successMessage"
      color="green"
      class="mt-2"
      :allow-close="false"
    >
      {{ successMessage }}
    </YAlert>
    <portal-target name="errors"></portal-target>
    <div class="flex justify-between border-t border-gray-500 pt-4 mt-3">
      <p class="text-gray-700">Don't have an account?</p>
      <router-link :to="{ name: 'Register' }">
        <p class="link">Sign Up</p>
      </router-link>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
      portal-to="errors"
      alert-class="text-xs"
      :icon-size="12"
      :icon="null"
      color="yellow"
    ></ValidationErrors>
  </div>
</template>

<script>
import FORGOT_PASSWORD_MUTATION from '@/graphql/Auth/ForgotPasswordMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  data() {
    return {
      email: null,
      successMessage: null,
      isSubmitting: false,
      requestSent: false
    }
  },
  methods: {
    submit() {
      if (this.requestSent || this.isSubmitting) {
        return
      }
      this.isSubmitting = true
      this.clearValidationErrors()
      this.$apollo
        .mutate({
          mutation: FORGOT_PASSWORD_MUTATION,
          variables: {
            input: {
              email: this.email
            }
          }
        })
        .then(({ data }) => {
          this.clearValidationErrors()
          this.isSubmitting = false
          if (data.forgotPassword.status == 'EMAIL_SENT' && !this.requestSent) {
            this.successMessage = 'We have emailed your password reset link!'
            this.requestSent = true
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    detectEnterKey(event) {
      if (event.code === 'Enter') {
        this.submit()
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', this.detectEnterKey)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.detectEnterKey)
  }
}
</script>

<style></style>
