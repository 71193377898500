<template>
  <div>
    <div class="grid grid-cols-3 gap-2 py-2">
      <!-- signed up on -->
      <div
        class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
      >
        <div
          class="uppercase text-xs font-semibold text-gray-800 flex justify-between"
        >
          Signed Up On
          <Icon name="accountEdit" size="6" class="text-teal-500" />
        </div>
        <div class="text-xl font-bold">
          {{ user.createdAt | luxon }}
        </div>
        <div class="flex justify-between pt-1 text-xs text-gray-800">
          <div v-if="user.createdMeta">
            <template v-if="user.createdMeta.ip">
              <span class="opacity-50">
                {{ user.createdMeta.ip }}
              </span>
              <a
                :href="
                  `https://www.ip2location.com/demo/${user.createdMeta.ip}`
                "
                target="_blank"
                class="cursor-pointer"
              >
              </a>
            </template>
          </div>
        </div>
      </div>
      <!-- agreed to terms -->
      <div
        class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
      >
        <div
          class="uppercase text-xs font-semibold text-gray-800 flex justify-between"
        >
          Agreed to Terms
          <Icon name="clock" size="6" class="text-purple-500" />
        </div>
        <div
          v-if="user.userDetails.agreedToTermsBy"
          class="-mt-2 opacity-50 text-xs text-gray-800"
        >
          by {{ agreedToTermsObject.agreedBy
          }}<span v-if="agreedToTermsObject.agreedByPosition"
            >, {{ agreedToTermsObject.agreedByPosition }}</span
          >
        </div>

        <div
          class="text-base font-bold"
          v-if="user.userDetails?.agreedToTerms !== true"
        >
          Has not agreed to terms
        </div>

        <div v-if="user.userDetails?.agreedToTerms == true">
          <div
            class="text-xl font-bold"
            v-if="user.userDetails.agreedToTermsAt !== '0000-00-00 00:00:00'"
          >
            {{ user.userDetails.agreedToTermsAt | luxon }}
          </div>
          <div
            class="flex justify-between pt-1 text-xs text-gray-800"
            v-if="user.userDetails"
          >
            <span class="opacity-50">
              {{ user.userDetails.agreedToTermsIp }}
            </span>
          </div>
        </div>
        <div
          class="-mt-1 opacity-50 text-xs text-gray-800 pt-1"
          v-if="user.userDetails?.legacyAgreedToTerms"
        >
          Legacy Terms {{ user.userDetails.legacyAgreedToTermsAt | luxon }}
        </div>
      </div>
      <!-- last login -->
      <div
        class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
      >
        <div
          class="uppercase text-xs font-semibold text-gray-800 flex justify-between"
        >
          Last Login
          <Icon name="logout" size="6" class="text-blue-500" />
        </div>

        <div class="text-base font-bold" v-if="!user.lastLogin">
          No Recent Data
        </div>

        <div v-if="user.lastLogin">
          <div
            class="text-xl font-bold"
            v-if="user.userDetails.agreedToTermsAt !== '0000-00-00 00:00:00'"
          >
            {{ user.lastLogin.createdAt | luxon }}
          </div>
          <div
            class="flex justify-between pt-1 text-xs text-gray-800"
            v-if="user.userDetails"
          >
            <span class="opacity-50">
              {{ userActivity[0]?.properties?.ip }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <Widget class="col-span-2">
      <WidgetHeader class="bg-gray-100">User Log</WidgetHeader>
      <WidgetBody>
        <div class="widget-edge">
          <YTable :data="userActivity" v-if="userActivity">
            <template slot="header-row">
              <th>User (Causer)</th>
              <th>User (Subject)</th>
              <th>Description</th>
              <th>Properties</th>
              <th>Time</th>
            </template>
            <template slot="row" slot-scope="props">
              <td>
                <router-link
                  v-if="props.rowData.causer"
                  :to="{
                    name: 'User',
                    params: { id: props.rowData.causer.id }
                  }"
                  class="link"
                  >{{ props.rowData.causer.label }}</router-link
                >
              </td>
              <td>
                <router-link
                  v-if="props.rowData.subject"
                  :to="{
                    name: 'User',
                    params: { id: props.rowData.subject.id }
                  }"
                  class="link"
                  >{{ props.rowData.subject.label }}</router-link
                >
              </td>
              <td>{{ props.rowData.description }}</td>
              <td>
                <template v-if="props.rowData.properties">
                  <UaParser :input="props.rowData.properties.useragent">
                    <template v-slot:default="uaProps">
                      <ul>
                        <li>
                          <a
                            :href="
                              `https://www.ip2location.com/demo/${props.rowData.properties.ip}`
                            "
                            target="_blank"
                            class="link inline-flex items-center"
                          >
                            <Flag
                              v-if="props.rowData.properties.countryCode"
                              class="mr-2 rounded-sm overflow-hidden"
                              :country="props.rowData.properties.countryCode"
                            />
                            {{ props.rowData.properties.ip }}
                          </a>
                        </li>
                        <span v-if="uaProps.parsed != null">
                          <li>
                            {{ uaProps.parsed.browser.name }}
                            <span v-tooltip="uaProps.parsed.browser.version">{{
                              uaProps.parsed.browser.major
                            }}</span>
                          </li>
                          <li>
                            {{ uaProps.parsed.os.name }}
                            {{ uaProps.parsed.os.version }}
                          </li>
                          <li>{{ uaProps.parsed.device.type }}</li>
                        </span>
                      </ul>
                    </template>
                  </UaParser>
                </template>
              </td>
              <td>
                <span
                  v-tooltip="
                    $luxon(props.rowData.createdAt, 'yyyy-MM-dd HH:mm:ss')
                  "
                >
                  {{ props.rowData.createdAt | luxonRelative }}
                </span>
              </td>
            </template>
          </YTable>
        </div>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
import UaParser from '@/components/utilities/UaParser'
import TagDefaultColors from '@/mixins/TagDefaultColours.js'

export default {
  mounted() {},
  mixins: [TagDefaultColors],
  components: { UaParser },
  props: {
    user: {
      type: Object,
      required: true
    },
    userActivity: {
      type: Array,
      required: true
    }
  },
  computed: {
    agreedToTermsObject() {
      return JSON.parse(this.user.userDetails.agreedToTermsBy)
    }
  }
}
</script>

<style scoped>
.comments:nth-child(odd) {
  @apply bg-gray-100 rounded;
}
</style>
